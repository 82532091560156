import axios from 'axios';
import { APIResponse, EventCatalogResponse, Location, PublicEventGroup, BankTransferData, SubdomainResponse, SearchEvent } from '@arema/components/Classes';
const ROOT_URL = process.env.REACT_APP_API_URL+'/public'
const SEARCH_URL = process.env.REACT_APP_SEARCH_URL;
const DEBUG = process.env.REACT_APP_DEBUG=='1';

interface PostOpts{
	root?: string
}

async function post<T=any>(endpoint: string, data?: any, opts: PostOpts={}) : Promise<APIResponse<T>>{
	if(!opts) opts = {};
	var options = {
		headers: {
			'show-code': DEBUG ? 1 : 0,
			'Content-Type': 'application/json'
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${opts.root || ROOT_URL}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, options);
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: 'Hubo un error inesperado (APIP-LCL-1)',
			code: 'APIP-LCL-1',
		}
	}
};

var CACHE : { [x: string]: APIResponse<any> } = {};
var ACCESS_TOKENS : { [x: number]: any } = {};

var cachePost = async <T=any>(endpoint: string, key: string, data?: any, force: boolean=false) : Promise<APIResponse<T>>=>{
	if(CACHE[key] && !force) return CACHE[key] as APIResponse<T>;
	var res = await post<T>(endpoint, data);
	if(!res.error) CACHE[key] = res;
	return res;
}

var getEventCatalog = (legacy: boolean=true)=>{
	return cachePost<EventCatalogResponse>('events/list', `event_catalog-${legacy ? 1 : 0}`, { legacy });
}

var getEvent = (event_id: number, subdomain: string, access: string, force: boolean=false)=>{
	return cachePost('events/get', `ev-${event_id}-${subdomain}`, { event_id, subdomain, access }, force);
}

var getLegacyEvent = (idevento: number, access: string, force: boolean=false)=>{
	return cachePost('events/legacy/get', `evl-${idevento}`, { idevento, access }, force);
}

var getSubdomain = (subdomain: string)=>{
	return cachePost<SubdomainResponse>('events/subdomain/get', `evsub-${subdomain}`, {
		subdomain
	});
}

var createRefundWeb = (order_hash: string, order_email: string, payment_method: number, captcha: string)=>{
	return post<{ token: string }>('refunds/search', { order_hash, order_email, payment_method, captcha });
}

var createRefundPdv = (tickets: { ticket_hash: string, ticket_cost: number }[], payment_method: number, captcha: string)=>{
	return post<{ token: string }>('refunds/search', { tickets, payment_method, captcha });
}

var getRefundableData = (token: string, request: boolean=false)=>{
	return post('refunds/form', { token, request });
}

var finishRefund = (token: string, data: BankTransferData, request: boolean=false)=>{
	return post<{ token: string }>('refunds/create', { token, request, ...data });
}

var getRefund = (token: string)=>{
	return post('refunds/get', { token });
}

var searchTickets = (order_hash: string, email: string, captcha?: string)=>{
	return post<{ tickets_url: string }>('tickets/search', { order_hash, email, captcha });
}

interface InvoiceData{
	razon_social: string,
	rfc: string,
	regimen_fiscal: string,
	uso_cfdi: string,
	address: Location
}

var createInvoice = (order_hash: string, email: string, data: InvoiceData, captcha?: string)=>{
	return post<{ created: boolean, invoice_url: string }>('orders/invoice', {
		order_hash, email, captcha,
		...data
	});
}

var getEventGroup = (group_id: number)=>{
	return cachePost<PublicEventGroup>('group/get', `evgo-${group_id}`, { group_id });
}

var getLegacyGroup = (idpadre: number)=>{
	return cachePost<PublicEventGroup>('group/legacy/get', `evgl-${idpadre}`, { idpadre });
}

var sendRefundInfoRequest = (token: string, data: BankTransferData)=>{
	return post('refunds/info', { token, ...data });
}

var getAccessCode = async (event_id: number, code: string, captcha: string, legacy: boolean=false)=>{
	var res = await post<{ token: string }>('events/access', { event_id, code, captcha, legacy });
	if(!res.error && res.data.token){
		ACCESS_TOKENS[event_id] = res.data.token;
	}
	return res;
}

var getAccessToken = (event_id: number)=>{
	return ACCESS_TOKENS[event_id];
}

var setAccessToken = (event_id: number, token: string)=>{
	ACCESS_TOKENS[event_id] = token;
}

var getPopular = (legacy: boolean=true)=>{
	return post<{ bestsellers: SearchEvent[], trends: string[] }>('events/popular', { bestsellers: true, trends: true, legacy });
}

var searchEvents = (query: string)=>{
	return post<{ count: number, max_count: number, events: SearchEvent[] }>('search', { query }, {
		root: SEARCH_URL
	});
}

export default {
	createInvoice,
	createRefundPdv,
	createRefundWeb,
	finishRefund,
	getAccessCode,
	getAccessToken,
	getEvent,
	getEventCatalog,
	getEventGroup,
	getLegacyEvent,
	getLegacyGroup,
	getPopular,
	getRefund,
	getRefundableData,
	getSubdomain,
	searchEvents,
	searchTickets,
	sendRefundInfoRequest,
	setAccessToken,
}